import { Question } from './GenericFormRenderer';

import * as Yup from 'yup';

export const getValidationSchema = (questions: Question[]) => {
  const shape: Record<string, any> = {};

  questions.forEach((question) => {
    if (question.mandatory) {
      shape[question.field] = Yup.mixed().test(
        'conditional-required',
        'This field is required',
        function (value) {
          const { dependentFields, dependentValues } = question;

          if (!dependentFields || !dependentValues) return !!value; // No conditions, pass validation

          const parentSchema = (this.options as any).parent; // Access the schema context
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const dependentFieldValue =
            (parentSchema as Record<string, any>)?.[dependentFields[0]] || undefined;

          // Check if dependentField's value matches dependentValues[0]
          if (dependentFieldValue === dependentValues[0]) {
            return !!value; // Value must exist if condition matches
          }

          return true; // No requirement otherwise
        },
      );
    }
  });

  return Yup.object().shape(shape);
};
