import React, { FC, useEffect, useState } from 'react';
import { isEmpty } from '../../helper/Validation';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { useStyles } from './InputDateStyles';
import CompletedIcon from '../../assets/check.svg';
import IncompleteIcon from '../../assets/filled-circle.svg';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import calendar from '../../assets/calendar.png';
import { Moment } from 'moment';

interface InputDateProps {
  value: string | Date | null | undefined;
  placeholder: string;
  title?: string;
  mandatory?: boolean;
  touched?: boolean;
  error?: string | null;
  disabled?: boolean;
  parentStyles?: string;
  parentInputStyles?: string;
  password?: boolean;
  endElement?: any;
  showRequiredError?: boolean;
  maxDate?: string | Date;
  minDate?: string | Date | Moment;
  yearMonthOnly?: boolean;
  onBlur?: () => void;
  onChange?: () => void;
  setValue: (value: string | Date | null | undefined) => void;
  shouldDisableDate?: ((day: string | Date) => boolean) | undefined;
  defaultCalendarMonth?: string | Date | Moment;
}

const InputDate: FC<InputDateProps> = ({
  title,
  placeholder,
  value,
  mandatory,
  touched,
  error,
  disabled,
  parentStyles,
  parentInputStyles,
  password,
  endElement,
  showRequiredError,
  maxDate,
  minDate,
  yearMonthOnly,
  onBlur,
  setValue,
  shouldDisableDate,
  onChange,
  defaultCalendarMonth,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean | undefined>(false);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        value={value}
        label="Basic example"
        open={open}
        orientation="landscape"
        disabled={disabled}
        maxDate={maxDate}
        minDate={minDate}
        inputFormat="DD/MM/YYYY"
        onClose={() => setOpen(false)}
        onAccept={() => setOpen(false)}
        onChange={(newValue: string | Date) => {
          setValue(newValue);
          if (onChange) onChange();
        }}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <div className={classNameGenerator([classes.inputCard, parentStyles])}>
            {(touched && error) || (showRequiredError && isEmpty(value)) ? (
              <div className={classes.errorTextStyle}>{error || 'Required'}</div>
            ) : (
              <>
                {title && (
                  <div className={classes.titleContainer}>
                    <div className={classes.titleStyle}>{title}</div>
                    <div className={classes.mandatory}>{mandatory ? '*' : ''}</div>
                  </div>
                )}
              </>
            )}
            <div
              className={classNameGenerator([
                classes.inputContainer,
                ((touched && error) || (showRequiredError && isEmpty(value))) && classes.errorInput,
                disabled && classes.disabledContainer,
              ])}
            >
              <input
                ref={inputRef}
                {...inputProps}
                type={password ? 'password' : 'text'}
                className={classNameGenerator([classes.inputStyle, parentInputStyles])}
                placeholder={placeholder}
                onBlur={onBlur}
                disabled={disabled}
              />
              <div className={classes.calendarContainer} onClick={() => setOpen(!open)}>
                <img
                  src={calendar}
                  className={classNameGenerator([classes.calendar, disabled && classes.disabled])}
                  alt="arrow"
                />
              </div>
              <div className={classes.endElement}>{endElement}</div>
              {((touched && error) || (showRequiredError && isEmpty(value)) || value) && (
                <img
                  className={classes.endStatus}
                  src={
                    (touched && error) || (showRequiredError && isEmpty(value))
                      ? IncompleteIcon
                      : value
                      ? CompletedIcon
                      : ''
                  }
                  alt=""
                />
              )}
              {/* {InputProps?.endAdornment} */}
            </div>
          </div>
        )}
        shouldDisableDate={shouldDisableDate}
        defaultCalendarMonth={defaultCalendarMonth}
      />
    </LocalizationProvider>
  );
};

export default InputDate;
