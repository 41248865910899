/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';
import { Location } from 'history';
import {
  isAdditionalProfileDetailsFilled,
  isPropertiesDetailsFilled,
  verifyDeclarationDetails,
  verifyProfileDetails,
  verifySeconadaryDetails,
} from '../../helper/ProfileVerifyHelper';
import { DependentProfile } from '../../services/additionalPersonalDetailsScreen/postFormDetails/PostAdditionalPersonalDetailsForm.data';
import { Address, ApplicantProfile, CurrentIncome } from '../../store/state/MyProfileFormState';
import { AppToApplyTransitionParams } from './ApplicationConstants';
import { BranchConfig } from '../../services/config/branchConfigType';

export const getQueryParams = (location: Location, param: string): string => {
  const query = location.search.substring(1);
  const vars = query.split('&');
  let returnVal = '';
  vars.forEach((key: string) => {
    const pair = key.split('=');
    if (decodeURIComponent(pair[0]) === param) {
      returnVal = decodeURIComponent(pair[1]);
    }
  });
  return returnVal;
};

export const getAllQueryParams = (location: Location): AppToApplyTransitionParams => {
  const agency = getQueryParams(location, 'agency');
  const applicationRefIds = getQueryParams(location, 'applicationRefIds');
  const draftIds = getQueryParams(location, 'draftIds');
  const groupRefId = getQueryParams(location, 'groupRefId');
  const responseType = getQueryParams(location, 'responseType');
  const updatingOne = getQueryParams(location, 'updatingOne');

  return {
    agency,
    applicationRefIds,
    draftIds,
    groupRefId,
    responseType,
    updatingOne,
  };
};

export const canSubmitApplication = (
  myProfileForm: ApplicantProfile,
  secondaryApplicantProfiles: ApplicantProfile[],
  dependentProfiles: DependentProfile[],
): { valid: boolean; reason: string } => {
  let reason = '';
  let valid = true;

  if (!isPropertiesDetailsFilled()) {
    valid = false;
    reason = 'Your selected properties are missing information';
  }

  if (!verifyProfileDetails(myProfileForm)) {
    valid = false;
    reason = 'Your profile is incomplete';
  } else if (verifyDeclarationDetails(myProfileForm)) {
    valid = false;
    reason = "The declaration form hasn't been accepted";
  }

  if (secondaryApplicantProfiles && secondaryApplicantProfiles.length) {
    secondaryApplicantProfiles.map((tenant, key) => {
      if (tenant.personalDetails && tenant.personalDetails.isManaged) {
        if (!verifySeconadaryDetails(secondaryApplicantProfiles, key)) {
          valid = false;
          reason = 'Your managed profiles are incomplete';
        } else if (!tenant) {
          valid = false;
          reason = 'Your managed profiles are incomplete';
        }
      }
    });
  }

  if (dependentProfiles && dependentProfiles.length) {
    dependentProfiles.map((tenant) => {
      if (!isAdditionalProfileDetailsFilled(tenant)) {
        valid = false;
        reason = 'Your managed profiles are incomplete';
      }
    });
  }

  return { valid, reason };
};

export const validateAgainstConfig = (config: BranchConfig, profile: ApplicantProfile) => {
  const {
    customProfileConfig: { validationSchema, agencyName },
  } = config;

  const schema = Yup.object().shape({
    referencesDetails: Yup.object().shape({
      references: Yup.array()
        .of(Yup.object())
        .when([], {
          is: () => validationSchema.minPersonalReferences !== undefined,
          then: Yup.array().min(
            validationSchema.minPersonalReferences!,
            `${agencyName} requires you to attach at least ${validationSchema.minPersonalReferences} personal reference(s)`,
          ),
        }),
    }),

    // TODO: Add validation for payslipData and bankstatements if a config requires it in future

    addressDetails: Yup.object().shape({
      addresses: Yup.array()
        .of(
          Yup.object().shape({
            durationOfStayYear: Yup.number().required(),
            durationOfStayMonth: Yup.number().required(),
          }),
        )
        .test(
          'total-address-months', // Test name
          `${agencyName} requires address history for at least ${validationSchema.minAddressHistoryMonths} month(s)`, // Error message
          (addresses: Address[]) => {
            if (!validationSchema.minAddressHistoryMonths) return true; // Skip validation if not defined

            // Calculate total duration in months
            const totalMonths: number = addresses?.reduce((sum: number, address) => {
              const yearsInMonths = (address.durationOfStayYear || 0) * 12;
              const months = address.durationOfStayMonth || 0;
              return sum + yearsInMonths + months;
            }, 0);

            // Compare total months with the required minimum
            return totalMonths >= validationSchema.minAddressHistoryMonths;
          },
        ),
    }),

    identificationDetails: Yup.object().shape({
      IDPoints: Yup.number()
        .nullable()
        .when([], {
          is: () => validationSchema.minIdentificationPoints !== undefined,
          then: Yup.number().min(
            validationSchema.minIdentificationPoints!,
            `${agencyName} requires at least ${validationSchema.minIdentificationPoints} identification points`,
          ),
        }),
    }),
  });

  try {
    schema.validateSync(profile, { abortEarly: false });
    return { isValid: true, errors: [] };
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      // Extract errors with paths and messages
      const detailedErrors = error.inner.map((err) => ({
        [err.path]: err.message,
      }));
      return {
        isValid: false,
        errors: Object.assign({}, ...detailedErrors),
      };
    }
    throw error;
  }
};
