import { ProfileQuestion } from '../../../store/actions/CustomTermsHeaderActions';
import { ApplicationState } from '../../../store/RootReducers';
import { FileData } from '../../../store/state/FileUploadState';
import {
  DigitalSignature,
  ManualAddressData,
  MedicareColours,
  PostPersonalDetailsFormData,
} from '../../../store/state/MyProfileFormState';
import { AustraliaState } from '../australiaStates/australiaStates';

export interface ApplyFormPayload {
  applyForm: ApplyForm;
  refId: string;
}

export interface ApplyPropertyFormPayload {
  applyForm: ApplyPropertyForm;
  refId?: string;
}

export interface PropertyData {
  propertyRefId: string | undefined;
  rentOffer: number | undefined;
  inspectionDate: string | null;
  agency?: string | null | undefined;
}

export enum ApplicationFormStep {
  APPLICATION = 'Application',
  ADDITIONAL_DOCUMENTS = 'Additional Documents',
  SUBMIT = 'Submit',
}

export interface ApplyPropertyForm {
  propertyList: PropertyData[];
  applicationRequest: {
    email: string | null | undefined;
    name: string;
    rentOffer: number;
    years?: number;
    months?: number;
    preferredMoveInDate?: string | null;
    noOfPeople: number;
    noOfPets: number;
    noOfVehicles: number;
    applicants: Applicants[];
    currentStep?: string;
  };
  userId?: string;
  draftId?: string;
  title?: string;
  lastModifiedDate?: string;
}

export interface ApplyForm {
  email: string | null | undefined;
  name: string;
  rentOffer: number;
  years?: number;
  months?: number;
  preferredMoveInDate?: string | null;
  noOfPeople: number;
  noOfPets: number;
  noOfVehicles: number;
  applicants: Applicants[];
}

export interface Applicants {
  key?: number | undefined;
  refId?: string | null;
  id?: number | null;
  title?: string | null;
  firstName: string | null | undefined;
  middleName: string | null | undefined;
  lastName: string | null | undefined;
  email: string | null | undefined;
  dob: string | null;
  mobile: string | null | undefined;
  applicantType: string | undefined;
  ageRange?: string | null;
  guarantorName: string | null | undefined;
  guarantorMobile: string | null | undefined;
  guarantorEmail: string | null | undefined;
  digitalSignature?: DigitalSignature;
  isSortedTermsAccepted?: boolean | null | undefined;
  declarationDate?: string | null;
  hasBeenEmployed: boolean | undefined | null;
  hasPets: boolean | undefined | null;
  hasVehicles: boolean | undefined | null;
  addReference: boolean | undefined | null;
  applicantAddressHistory: AddressHistory[] | null;
  applicantIdentification: Identification[] | null;
  applicantEmergencyContact: EmergencyContact[] | null;
  applicantEmploymentHistory: EmploymentHistory[] | null;
  applicantIncome: Income[] | null;
  applicantPet: Pet[] | null;
  applicantVehicles: Vehicle[] | null;
  applicantReference: Reference[] | null;
  createdBy?: string;
  createdDate?: string | null | undefined;
  lastModifiedDate?: string | null | undefined;
  lastModifiedBy?: string;
  isManaged?: boolean | null | undefined;
  introduction: string | null;
  questions: ProfileQuestion[] | null;
  selectedState?: AustraliaState | null;
  ntdPurchased: boolean | null;
  ntdActive: boolean | null;
  ntdDate: string | null;
}

export interface AddressHistory {
  applyReferenceId?: string | null;
  address: string | null;
  addressComponents: ManualAddressData | null;
  currentLivingArrangement: string | null;
  years: number | null;
  months: number | null;
  propertyManagerName: string | null;
  mobile: string | null;
  monthlyRent: string | null | number;
  reasonForLeaving: string | null;
  postalAddress: string | null;
  landlord: string | null;
  agencyName: string | null;
  current: boolean | null;
  propertyManagerEmail: string | null;
  submissionStatus?: string | null;
  isDisabled?: boolean;
  mailSent?: boolean;
  additionalAddressDocuments: FileData[];
  rank: number | null;
}
export interface ExtendedData {
  stateOfIssueDL?: null | string;
  countryOfOrigin?: string | null;
  cardNumber?: string | null;
  medicareColour?: MedicareColours | null | string;
  expiryDate?: Date | string | null;
}
export interface Identification {
  type: string | null;
  identificationLink: string | null;
  idNumber?: string | null;
  extendedData?: ExtendedData;
  stateOfIssueDL?: null | string;
  licenseNumber?: string | null;
  countryOfOrigin?: string | null;
  passportNumber?: string | null;
  cardNumber?: string | null;
  referenceNumber?: string | null;
  medicareColour?: MedicareColours | null | string;
  expiryDate?: Date | string | null;
}

export interface EmergencyContact {
  name: string;
  relationship: string;
  mobile: string;
  address?: string;
  email: string | null;
}

export interface EmploymentHistory {
  applyReferenceId?: string | null;
  startDate: string | null | number;
  endDate: string | null | number;
  stillInJob: boolean | null;
  companyName: string | null;
  position: string | null;
  annualSalary: string | null | number;
  managerName: string | null;
  managerPhone: string | null;
  coverLetterLink: string | null;
  employerEmail: string | null;
  submissionStatus?: string | null;
  isDisabled?: boolean;
  mailSent?: boolean;
  address: string | null;
  addressComponents: ManualAddressData | null;
  rank: number | null;
}

export interface Income {
  source: string | null;
  other: string | null;
  weeklyAmount: string | null | number;
  savingsAmount: string | null | number;
  guarantorName: string | null;
  guarantorEmail: string | null;
  guarantorMobile: string | null;
  payslipLink: FileData[];
  benefitProofLink: FileData[];
  incomeProofLink: FileData[];
  bankStatementLink: FileData[];
  crn: string | null;
}

export interface Vehicle {
  type: string | null;
  year: number | null;
  make: string | null;
  model: string | null;
  state: string | null;
  identificationLink: string | null;
  registrationNumber: string | null;
}

export interface Pet {
  type: string | null;
  breed: string | null;
  count: number | null;
  size: string | null;
  desexedStatus: boolean | null;
  additionalPetDocuments: FileData[];
  registrationNumber: string | null;
}

export interface Reference {
  applyReferenceId?: string | null;
  name: string | null;
  relationship: string | null;
  email: string | null;
  mobile: string | null;
  submissionStatus?: string | null;
  isDisabled?: boolean;
  mailSent?: boolean;
  rank: number | null;
}

export interface SubmittedApplicationResponse {
  key?: number | undefined;
  id: number;
  refId: string;
  propertyRefId: string | undefined | null;
  email: string;
  name: string;
  rentOffer: number;
  bondAmount: number;
  years: number;
  months: number;
  preferredMoveInDate: string | null;
  noOfPeople: number;
  noOfPets: number;
  noOfVehicles: number;
  leaseId: null;
  inspectionDate: string | null | undefined;
  applicants: Applicants[];
  // For the applicants before login
  otherApplicants?: Applicants[];
  formattedCreatedDate: string | null | undefined;
  createdBy: string;
  createdDate: string | null | undefined;
  lastModifiedDate: string | null | undefined;
  lastModifiedBy: string;
  status: SubmittedApplicationStatusResponse[];
  title: string | null | undefined;
  updateRequiredStatus: boolean;
  masterProfileVersion: number;
}

export interface SubmittedApplicationStatusResponse {
  id: number;
  status: string;
  createdBy: string;
  createdDate: string | null | undefined;
  lastModifiedDate: string | null | undefined;
  lastModifiedBy: string;
  current: boolean;
}

export interface GetApplicationsPayload {
  readonly userId: string;
}

export interface PatchTitlePayload {
  readonly userId: string;
  readonly draftId: string;
  readonly title: string;
}

export interface DeleteDraftRequest {
  draftId: string;
}

export interface ReferencesStateChanges {
  profileRefId: string | null;
  applicantAddressHistory: AddressHistory[] | null;
  applicantEmploymentHistory: EmploymentHistory[] | null;
  applicantReference: Reference[] | null;
}

export interface MasterProfileResponsePayload {
  primary: ApplicantResponsePayload;
  secondary: ApplicantResponsePayload[];
  dependents: ApplicantResponsePayload[];
}
export interface ApplicantResponsePayload {
  profileData: Applicants;
  type: string;
}

export interface MasterProfilePayload {
  applicants: Applicants[];
  years?: number;
  months?: number;
  preferredMoveInDate?: string | null;
}

export interface ReferencesPayload {
  profileRefId: string | null | undefined;
  applicantAddressHistory: AddressHistory[] | null;
  applicantEmploymentHistory: EmploymentHistory[] | null;
  applicantReference: Reference[] | null;
}

export interface SignDataPayload {
  masterProfileData: SubmittedApplicationResponse | null;
  personalDetails: PostPersonalDetailsFormData | undefined;
  digitalSignature: DigitalSignature;
  refId: string | null | undefined;
  success?: () => void;
}

export interface RefIdPairs {
  refId: string | null;
  propertyRefId: string | null;
}

export interface BulkUpdateRequest {
  applicationState: ApplicationState;
  refIdPairs: RefIdPairs[];
}

export interface BulkApplyPropertyFormPayload {
  applyForm: ApplyPropertyForm;
  refIdPairs: RefIdPairs[];
}
